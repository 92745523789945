import init,{initKeycloak} from './init'
import roles from './roles'
import logout from './logout'
import header from './header'

export default {
  init,
  roles,
  logout,
  header,
  initKeycloak
}
