import Vue from 'vue';
import Router from 'vue-router';
import LicenseView from './components/LicenseView.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        roles: ["license_manager"]
      },
      component: LicenseView,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import(/* webpackChunkName: "about" */ './views/Unauthorized.vue'),
    }
  ],
});
