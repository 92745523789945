import Keycloak from 'keycloak-js';
import store from '../index'

export let $keycloak;

export function initKeycloak({url, realm, clientId}: {url: string, realm: string, clientId: string}) {
  $keycloak = new Keycloak({url,realm, clientId});
  return $keycloak;
}

export default async (next, roles?) => {
  let keycloakAuth = $keycloak;
  let authenticated = await keycloakAuth.init({ onLoad: 'login-required', responseMode: 'fragment', checkLoginIframe: 'false' })
    .catch((error) => {
      console.error("KEYCLOAK INIT ERROR: " + error)
    })
  if (!authenticated) {
    window.location.reload()
  }
  store.dispatch('authLogin', keycloakAuth)
  if (roles) {
    if (keycloakAuth.hasResourceRole(roles[0])) {
      next()
    }
    else {
      next({ name: 'Unauthorized' })
    }
  }
  else {
    //next()
  }
  setInterval(function () {
    console.log("UPDATE TOKEN...")
    keycloakAuth.updateToken(70)
      .success((refreshed) => {
        if (refreshed) {
          store.dispatch('authLogin', keycloakAuth)
        }
        else {
          console.log('Token not refreshed, valid for ' + Math.round(keycloakAuth.tokenParsed.exp + keycloakAuth.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).error(function () {
        console.error('Failed to refresh token')
      })
  }, 60000)
}
