import AbstractAxiosInstance from './AbstractAxiosInstance';
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import BasicAxiosInstance from './BasicAxiosInstance';
import ILicenseManagerAxiosInstance from '../ILicenseManagerAxiosInstance';
import { FetchOptions } from '../ILicenseManagerAxiosInstance';
import { DeviceLicensesOutDTO }  from '../model/licenses'

class LicenseManagerAxiosInstance extends AbstractAxiosInstance implements ILicenseManagerAxiosInstance {
  constructor() {
    super();
    this.setBaseUrl(CommunicationSettings.licenseManagerApiUrl());
    this.setRequestInterceptor(async function(config: InternalAxiosRequestConfig) {
      try {
        await BasicAxiosInstance.genericAccessToken();
        config.headers['corvina-realm-token'] = BasicAxiosInstance.$corvina.accessToken;
      } catch (e) {
        console.error(e);
      }
      return config;
  });
  }
  public activateDevice(data: any): Promise<void> {
    return this.post('/api/v1/deviceLicenses/activate', data);
  }
  
  public fetchLicenses(options : FetchOptions): Promise<DeviceLicensesOutDTO> {
    let params : any = options.pageSize > 0 ? { page: options.page, pageSize: options.pageSize } : { page: 0, pageSize: 999999999 };
    params.orderBy = options.orderBy
    params.orderDir = options.orderDir
    params.filter = options.filter
    const config: AxiosRequestConfig = {
      params
    };
    return this.get('/api/v1/deviceLicenses', config);
  }

  public createLicense(serialNumber: string, activationKey: string,  customer: string, notes: string, vpnValidityMonths: number, vpnAccountingDisabled: boolean, vpnStartDate: string, vpnEndDate: string) : Promise<DeviceLicensesOutDTO> {
    const data = { serialNumber: serialNumber, activationKey: activationKey, notes: notes, clientName: customer, vpnValidityMonths, vpnAccountingDisabled, vpnStartDate, vpnEndDate }
    return this.post('/api/v1/deviceLicenses',  data);
  }

  public deleteLicense(id: string) {
    return this.delete('/api/v1/deviceLicenses/' + id );
  }

  public editLicense(id: string, serialNumber: any, customer: string, notes: string) {
    return this.put('/api/v1/deviceLicenses/' + id,  { serialNumber: serialNumber, clientName: customer, notes: notes } );
  }
}

export default new LicenseManagerAxiosInstance() as ILicenseManagerAxiosInstance;
