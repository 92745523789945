import CommunicationSettings from '../../CommunicationSettings';
import AbstractAxiosInstance from './AbstractAxiosInstance';
import IBasicAxiosInstance from '../IBasicAxiosInstance';

export interface CorvinaAxiosTokens {
    /** access_token  retrieved authenticating with keycloak */
    accessToken: any;
    /** token exchanged to access corvina platform */
    platformToken: any;
}

class BasicAxiosInstance extends AbstractAxiosInstance implements IBasicAxiosInstance {
  private corvina: CorvinaAxiosTokens;
  private inited: boolean;
  private genericTokenPromise: Promise<any>;
  constructor() {
    super();
    this.corvina = {
      accessToken: null,
      platformToken: null,
    };

    this.genericTokenPromise =  null;
  }

    /**
     * Getter $corvina
     * @return {CorvinaAxiosTokens}
     */
  public get $corvina(): CorvinaAxiosTokens {
    return this.corvina;
  }

  /* Funnel multiple parallel calls */
  public async genericAccessToken(): Promise<string> {
    try {
      if (this.genericTokenPromise) {
        // someone is already requesting this token and the request is 
        // ongoing... avoid doing a new one
        return await this.genericTokenPromise;
      } else {
        this.genericTokenPromise = this._genericAccessToken();
        const result = await this.genericTokenPromise
        this.genericTokenPromise = null
        return result
      }
    } catch(err) {
      this.genericTokenPromise = null
      throw(err)
    }
  }
  
  public async _genericAccessToken() : Promise<string> {
    try {
      await CommunicationSettings.keycloak().updateToken(30)
      this.corvina.accessToken = CommunicationSettings.keycloak().token
      return this.corvina.accessToken;
    } catch(axiosErr) {
      this.corvina.platformToken = null;
      console.error("Error retrieving standard token!!")
      console.log(axiosErr)
      throw axiosErr;
    }
  }
}

export default new BasicAxiosInstance() as IBasicAxiosInstance;
