import vuejsKeycloakSecurity from '../components/vuejs-keycloak/security';
import {default as vuejsKeycloak} from '../components/vuejs-keycloak/'

export default class CommunicationSettings {

    private static _keycloak: any;

    public static initKeycloak() {
        const keycloak = vuejsKeycloakSecurity.initKeycloak({
            url: CommunicationSettings.authApiUrl(),
            realm: CommunicationSettings.realm(),
            clientId: 'license-manager',
        })
        CommunicationSettings.setKeycloak(keycloak);
        return {
            isAuthenticated(): boolean {
                const auth = vuejsKeycloak.getters["SECURITY_AUTH"];
                return auth.authenticated;
            },
            initKeycloakAuth(next?, roles?) {
                return vuejsKeycloakSecurity.init(next, roles);
            },
            hasRole(role: string): boolean {
                return vuejsKeycloakSecurity.roles(role);
            }
        }
    }

    public static realm(): string {
        return 'master';
    }

    public static organization(): string {
        if (!CommunicationSettings.organizationCache) {
            this.realm(); // will compute it
        }
        return CommunicationSettings.organizationCache;
    }

    public static baseHostname(): string {
        const PREFIX = 'licenses.'
        if (!CommunicationSettings.baseHostnameCache) {
            const appPos = location.hostname.indexOf(PREFIX);
            if (appPos >= 0) {
                CommunicationSettings.baseHostnameCache = location.hostname.slice(appPos + PREFIX.length);
                if (location.port.length && (location.hostname != "localhost" && location.hostname != "127.0.0.1")) {
                    CommunicationSettings.baseHostnameCache += `:${location.port}`;
                }
            } else {
                CommunicationSettings.baseHostnameCache = 'corvina.fog:10443';
            }
        }
        return CommunicationSettings.baseHostnameCache;
    }

    public static restUrl(): string {
        return localStorage.getItem(CommunicationSettings.baseHostname() + '/restURL') || 'https://api.platform.' + CommunicationSettings.baseHostname() + '/appengine/v1';
    }

    public static authApiUrl(): string {
        return localStorage.getItem(CommunicationSettings.baseHostname() + '/authURL') || 'https://auth.' + CommunicationSettings.baseHostname() + '/auth';
    }

    public static licenseManagerApiUrl(): string {
        return localStorage.getItem(CommunicationSettings.baseHostname() + '/licenseURL') || `https://app.${CommunicationSettings.baseHostname()}/svc/license`;
    }

    public static deviceMappingApiUrl(): string {
        return localStorage.getItem(CommunicationSettings.baseHostname() + '/mappingsURL') || `https://app.${CommunicationSettings.baseHostname()}/svc/mappings`;
    }

    public static wsUrl(): string {
        return localStorage.getItem(CommunicationSettings.baseHostname() + '/wsURL') || 'wss://appengine.api.platform.' + CommunicationSettings.baseHostname() + '/';
    }

    public static keycloak(): any {
        return CommunicationSettings._keycloak;
    }

    public static setKeycloak(keycloak: any) {
        CommunicationSettings._keycloak = keycloak;
    }
    /* Corvina settings */
    /*
    "appApiUrl" : "https://app.api.platform.corvina.cloud/v1",
    "authApiUrl" : "https://auth.corvina.cloud/auth",
    "wsUrl" : "wss://channels.platform.corvina.cloud/socket"
    */

    private static baseHostnameCache: string;
    private static realmCache: string;
    private static organizationCache: string;

}
