<template>
    <v-app >
      <!--v-navigation-drawer v-model="drawer" app-->
         <!--v-list dense>
        <v-list-tile @click="">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Home</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="">
          <v-list-tile-action>
            <v-icon>contact_mail</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Contact</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list--> 
      </v-navigation-drawer>
      <v-app-bar  fixed app>
        <!--v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-ico-->
      <v-toolbar-title>Corvina licenses</v-toolbar-title>
      <div style="flex-grow: 1" ></div>
      <v-btn data-qa="logout" @click="logout" >Logout</v-btn>
    </v-app-bar>


      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
</template>


<script>

import LicenseView from './components/LicenseView'
import keycloakLogout from './components/vuejs-keycloak/security/logout'


export default {
  name: 'Reveal',
   components: {
     LicenseView
   },
    data: () => ({
      drawer: false
    }),
    methods: {
      logout() {
        keycloakLogout();
      }
    }
}
</script>
